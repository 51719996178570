import SimpleDialog from '@shared/js/services/simple_dialog.js'

up.compiler('[add-passenger-fields]', (element) => {
  const form = element.closest('form')

  function addPassengerFields() {
    const buildPassengerInput = document.createElement('input')
    buildPassengerInput.type = 'hidden'
    buildPassengerInput.name = 'build_passenger'
    buildPassengerInput.value = 'true'
    form.appendChild(buildPassengerInput)

    up.validate('.passenger-fields', { target: '.passenger-fields' }).finally(() => {
      buildPassengerInput.remove()
    })
  }

  element.addEventListener('click', addPassengerFields)
})

up.compiler('[remove-passenger-fields]', (element, { entrySelector }) => {
  const passenger = element.closest(entrySelector)

  function removePassengerFields() {
    const name = []
    passenger.querySelectorAll('.passenger-name-field').forEach((node) => name.push(node.value))
    const escapedName = up.util.escapeHTML(name.join(' '))

    const dialog = new SimpleDialog({
      title: element.textContent + '?',
      body: `Möchten Sie <b>${escapedName}</b> wirklich entfernen?`,
      noLabel: 'Abbrechen',
      yesLabel: 'Entfernen',
    })

    dialog.open().then(() => {
      const input = element.closest('.form-group').querySelector('input')
      input.value = 'true'
      up.validate('.passenger-fields', { target: '.passenger-fields' })
    }, () => {
    })
  }

  element.addEventListener('click', removePassengerFields)
})
