export default class SimpleDialog {
  constructor(options) {
    this.options = options
  }

  open() {
    const html = this.buildDialogBody(this.options)
    return new Promise((resolve, reject) => {
      up.layer.open({
        content: html,
        class: 'simple-dialog',
        size: 'medium',
        dismissable: false,
        onAccepted: resolve,
        onDismissed: reject,
      })
    })
  }

  buildDialogBody({ title, body, noLabel, yesLabel, buttonClass = 'btn-primary -primary' }) {
    return `
    <div class="flex">
      <h4 class="simple-dialog--headline">
        ${title}
      </h4>
      <div class="icon -cross -sky-blue -small" up-dismiss>
      </div>
    </div>
    ${body
      ? `
        <div class="simple-dialog--body">
          ${body}
        </div>
      `
      : ''
    }
    <div class="simple-dialog--footer">
      ${noLabel
        ? `
          <a href class="button -primary -small -outline" up-dismiss>
            ${noLabel.toUpperCase()}
          </a>
        `
        : ''
      }
      <a href class="btn button -small ${buttonClass}" up-accept>
        ${yesLabel.toUpperCase()}
      </a>
    </div>
    `
  }
}
