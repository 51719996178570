up.compiler('input[type="checkbox"], input[type="radio"]', (element) => {
  if (element.closest('.btn')) return

  const type = element.type

  element.classList.add('custom-control-input')
  element.classList.remove('form-check-input')

  const label = element.closest('label')

  if (label) {
    label.classList.add('custom-control')
    label.classList.add(`custom-${type}`)
  } else {
    const wrapper = document.createElement('span')
    wrapper.classList.add('custom-control', `custom-${type}`)
    element.parentNode.insertBefore(wrapper, element)
    wrapper.appendChild(element)
  }

  element.closest('.form-check-label')?.classList.remove('form-check-label')
  element.closest('.form-check')?.classList.remove('form-check')

  if (!element.querySelector('.custom-control-label')) {
    const customControlLabel = document.createElement('span')
    customControlLabel.classList.add('custom-control-label')
    element.after(customControlLabel)
  }
})
