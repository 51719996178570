import deTranslations from '../data/de/javascript.yml'
import enTranslations from '../data/en/javascript.yml'

const translations = Object.assign({}, deTranslations, enTranslations)
const locale = document.documentElement.lang

const I18n = {
  t(key) {
    const parts = key.split('.')
    let values = translations[locale]
    parts.forEach((part) => {
      if (typeof (values) === 'object') {
        values = values[part]
      }
    })
    if (typeof (values) !== 'undefined') {
      return values
    } else {
      return `missing translation for ${key}`
    }
  },
}

export default I18n
