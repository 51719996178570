up.compiler('[passengers-toggle]', (element) => {
  function toggleReadonly(input) {
    let attribute

    if (input.type === 'radio') {
      attribute = 'disabled'
    } else {
      attribute = 'readonly'
    }

    if (input.getAttribute(attribute)) {
      input.removeAttribute(attribute)
    } else {
      input.setAttribute(attribute, attribute)
    }
  }

  element.addEventListener('click', () => {
    const inputs = document.querySelector('.passengers').querySelectorAll('input')
    inputs.forEach(input => toggleReadonly(input))
  })
})
