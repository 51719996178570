import SimpleDialog from './simple_dialog'
import I18n from './i18n'

export default class SimpleConfirm extends SimpleDialog {
  constructor({ title, body, yesLabel, noLabel, buttonClass } = {}) {
    super({
      title: title || I18n.t('js.simple_dialog.default_title'),
      body: body || '',
      noLabel: noLabel || I18n.t('js.simple_dialog.default_no_label'),
      yesLabel: yesLabel || I18n.t('js.simple_dialog.default_yes_label'),
      buttonClass,
    })
  }
}

function onSimpleConfirm(event, onConfirm) {
  const target = event.target
  if (!target.confirmed && target.hasAttribute('simple-confirm')) {
    const { body, title, yes_label: yesLabel, no_label: noLabel, button_class: buttonClass } = JSON.parse(target.getAttribute('simple-confirm'))
    const dialog = new SimpleConfirm({ body, title, yesLabel, noLabel, buttonClass })
    dialog.open().then(() => {
      target.confirmed = true
      onConfirm(target)
    })
    event.preventDefault()
  }
}

up.on('up:link:follow', (event) => {
  onSimpleConfirm(event, up.follow)
})

up.on('click', 'button[type="submit"][simple-confirm]', (evt) => {
  onSimpleConfirm(evt, up.submit)
})
