import SimpleConfirm from '@shared/js/services/simple_confirm'
import I18n from '@shared/js/services/i18n'

up.compiler('[remove_nested]', (element, { entrySelector }) => {
  const formGroup = element.closest('.form-group')
  const input = formGroup.querySelector('input')

  element.addEventListener('click', (event) => {
    event.preventDefault()
    new SimpleConfirm({ body: I18n.t('js.really_remove') }).open().then(() => {
      if (input.isConnected) {
        input.value = '1'
        up.element.hide(formGroup.closest(entrySelector))
      }
    }, () => {})
  })
})
