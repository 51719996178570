up.form.config.watchInputDelay = 150
up.form.config.submitSelectors.push('form')

up.fragment.config.autoHistoryTargets.push('[up-main="overlay"]')
up.fragment.config.navigateOptions.transition = 'cross-fade'
up.fragment.config.runScripts = false

up.layer.config.modal.size = 'large'

up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link)')

up.network.config.badResponseTime = 300
up.network.config.progressBar = true

up.viewport.config.duration = 300

up.form.config.fieldSelectors = ['select', 'input:not([type=submit]):not([type=image])', 'button[type]:not([type=submit]):not([search--reset])', 'textarea']

up.history.config.updateMetaTags = true

if (document.body.dataset.environment === 'test') {
  // Disable animations globally so Capybara doesn't see duplicate elements during transitions
  up.motion.config.enabled = false

  // Disable concurrent requests
  up.network.config.concurrency = 1
}
