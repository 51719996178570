up.compiler('[attachment-form]', (element) => {
  const fileInputs = Array.from(element.querySelectorAll('[attachment-form--input]'))

  if (fileInputs.length > 0) {
    const submitButton = element.querySelector('[attachment-form--submit]')

    function toggleSubmitButton() {
      if (fileInputs.some(input => input.value)) {
        submitButton.disabled = false
      } else {
        submitButton.disabled = true
      }
    }

    toggleSubmitButton()
    fileInputs.forEach((input) => {
      input.addEventListener('change', toggleSubmitButton)
    })
  }
})
