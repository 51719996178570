import Datepicker from 'vanillajs-datepicker/Datepicker'
import de from 'vanillajs-datepicker/locales/de'

up.compiler('[date-picker]', (element) => {
  Object.assign(Datepicker.locales, de)
  const locale = document.documentElement.lang

  function dateFormat(locale) {
    switch (locale) {
      case 'de':
        return 'dd.mm.yyyy'
      case 'en':
        return 'yyyy-mm-dd'
    }
  }

  const options = {
    buttonClass: 'btn-sm',
    container: Array.from(document.querySelectorAll('up-modal-viewport')).at(-1) || document.body,
    autohide: true,
    language: locale,
    format: dateFormat(locale),
    weekStart: 1,
    maxView: 2,
    calendarWeeks: false,
    todayHighlight: true,
    orientation: 'bottom',
    prevArrow: '<button type="button" class="datepicker--previous btn btn-sm btn-outline-primary float-left"><i class="fa fa-caret-left"></i></button>',
    nextArrow: '<button type="button" class="datepicker--next btn btn-sm btn-outline-primary float-right"><i class="fa fa-caret-right"></i></button>',
  }

  const datepicker = new Datepicker(element, options)

  // required to allow live updates of opened datepicker from input field
  element.addEventListener('input', () => {
    const value = element.value
    datepicker.update({ autohide: false })
    element.value = value
  })

  // trigger change event when setting date from datepicker, required for unpoly autosubmit
  element.addEventListener('changeDate', () => up.emit(element, 'change'))

  // used to auto focus clock picker after selecting a date
  element.addEventListener('hide', () => {
    up.emit(element, 'date-picker:change', { value: datepicker.getDate() })
  })

  return () => datepicker.destroy()
})
