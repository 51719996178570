up.compiler('input[type=file]', (element) => {
  const form = element.closest('form')
  const cacheElement = form.querySelector(`#${element.id}_cache`)
  // As we send the cached file again in case of round trip errors it makes sense to show the user that the previous
  // selected file is still selected.
  if (cacheElement && cacheElement.value) {
    displaySelection(element, cacheElement.value)
  }

  element.addEventListener('change', (evt) => {
    const filename = String(evt.target.value)
    displaySelection(element, filename)
  })

  function displaySelection(element, filename) {
    const basename = filename.replace(/^.*[\\/]([^\\/]+)$/, '$1')

    element.closest('.custom-file').querySelector('.custom-file-label').textContent = basename
  }
})
