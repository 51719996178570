// https://makandracards.com/makandra/79164-unpoly-automatically-show-the-full-better_errors-page-when-rails-raises-an-error

if (document.body.dataset.environment === 'development') {
  // Returns true when your development server shows an error page.
  function isDevelopmentError(response) {
    return !response.ok && /Full backtrace|Better errors/.test(response.text)
  }

  // When the development server shows an error page, repeat the request as a full page load.
  up.on('up:fragment:loaded', function(event) {
    if (isDevelopmentError(event.response)) {
      event.preventDefault() // Prevent the fragment from being updated
      event.request.loadPage()
    }
  })
}
