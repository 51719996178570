export function getEnvironment() {
  return document.body.getAttribute('data-environment')
}

export function inTestEnvironment() {
  return getEnvironment() === 'test'
}

export function inDevEnvironment() {
  return getEnvironment() === 'development'
}
