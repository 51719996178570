up.compiler('.fade-in', (element) => {
  setTimeout(() => {
    element.classList.add('visible')
  })
})

function fadeIn(selector) {
  setTimeout(() => {
    document.querySelectorAll(selector).forEach((item) => item.classList.add('visible'))
  })
}

// If Unpoly is unavailable (e.g. failed sign-in POST-form where Unpoly won't boot), make sure elements still fade in
fadeIn('.fade-in')
