import TomSelect from 'tom-select/dist/js/tom-select.base'
// eslint-disable-next-line camelcase
import TomSelect_dropdown_input from 'tom-select/dist/js/plugins/dropdown_input'
// eslint-disable-next-line camelcase
import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button'
// eslint-disable-next-line camelcase
import TomSelect_clear_button from 'tom-select/dist/js/plugins/clear_button'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import hide from '@popperjs/core/lib/modifiers/hide'
import { inTestEnvironment } from '@shared/js/util/environment'


const texts = {
  de: {
    noResults: 'Keine Ergebnisse gefunden.',
    notLoading: 'Bitte mindestens 2 Zeichen eingeben.',
  },
  en: {
    noResults: 'No results found.',
    notLoading: 'Please enter 2 or more characters.',
  },
}

function t(key) {
  return (texts[document.documentElement.lang] || texts.en)[key]
}

TomSelect.define('dropdown_input', TomSelect_dropdown_input)
TomSelect.define('remove_button', TomSelect_remove_button)
TomSelect.define('clear_button', TomSelect_clear_button)

up.compiler('select:not([plain-select])', { priority: -50 }, (element, { clearButton = false, multiSelect = false, search = true, dropdownCssClass, ajax }) => {
  function dropdownParent() {
    return element.closest('up-modal') || null
  }

  const ajaxOptions = ajax
    ? {
        valueField: 'id',
        loadThrottle: ajax.delay,
        async load(query, resultsCallback) {
          const url = new URL(ajax.url, document.baseURI)
          url.searchParams.append('query', query)
          try {
            this.clearOptions()
            resultsCallback(await (await fetch(url)).json())
          } catch {
            resultsCallback()
          }
        },
        shouldLoad(query) {
          if (query.length >= ajax.minimumInputLength) {
            return true
          } else {
            this.clearOptions()
            return false
          }
        },
        render: {
          not_loading() {
            return `<div class="no-results text-muted">${t('notLoading')}</div>`
          },
        },
      }
    : {}

  const tagSelect = element.classList.contains('tags')

  const plugins = []
  if (search) {
    plugins.push('dropdown_input')
  }

  let maxItems = 1

  if (multiSelect) {
    maxItems = element.options.length
    plugins.push('remove_button')
  }

  if (clearButton) {
    plugins.push('clear_button')
  }

  const tomSelect = new TomSelect(element, {
    maxItems,
    create: tagSelect,
    addPrecedence: tagSelect,
    highlight: !tagSelect,
    dropdownParent: dropdownParent(),
    closeAfterSelect: true,
    maxOptions: null,
    plugins,
    ...(search ? {} : { controlInput: null }),
    onInitialize() {
      this.dropdown.style.margin = '0'
      // we use popperjs to position the dropdown, so that the dropdown is positioned on top or bottom,
      // depending on available space
      this.popper = createPopper(this.control, this.dropdown, {
        modifiers: [flip, preventOverflow, hide],
      })
      if (inTestEnvironment()) {
        // tom-select manipulates labels so features stop working
        const label = document.querySelector(`label[for='${element.id}-ts-control']`)
        if (label) {
          label.setAttribute('for', element.id)
        }
      }
      const placeholder = this.input.getAttribute('placeholder')
      const wrapper = this.wrapper
      if (dropdownCssClass) {
        wrapper.classList.add(dropdownCssClass)
      }
      if (placeholder) {
        const tsControl = wrapper.querySelector('.ts-control')
        if (tsControl) {
          tsControl.setAttribute('data-placeholder', placeholder)
        }
      }
    },
    ...ajaxOptions,
    render: {
      no_results() {
        return `<div class="no-results text-muted">${t('noResults')}</div>`
      },
      ...(ajaxOptions.render || {}),
    },
  })


  tomSelect.positionDropdown = function() {
    this.popper.update()
    this.dropdown.style.width = `${this.control.getBoundingClientRect().width}px`
  }

  tomSelect.focus = function() {
    // We unfortunately need to monkey-patch this, otherwise this will scroll the page
    // to weird positions.
    const self = this
    if (self.isDisabled) return
    self.ignoreFocus = true

    if (self.control_input.offsetWidth) {
      self.control_input.focus({ preventScroll: true }) // The preventScroll here is the change.
    } else {
      self.focus_node.focus()
    }

    setTimeout(() => {
      self.ignoreFocus = false
      self.onFocus()
    }, 0)
  }

  tomSelect.on('destroy', function() {
    if (this.popper) {
      this.popper.destroy()
    }
  })

  if (element.dataset.openAtTop) {
    tomSelect.on('dropdown_open', () => {
      setTimeout(() => {
        tomSelect.dropdown_content.scrollTo({ top: 0, behavior: 'instant' })
      })
    })
  }

  if (element.dataset.dropFocusOnClose || element.hasAttribute('up-validate')) {
    // This is mainly as a workaround for the following issue:
    // If the dropdown has an [up-validate] updating itself this will happen:
    // - User selects dropdown entry
    // - dropdown closes
    // - up-validate replaces dropdown
    // - unpoly notices that the select had focus, and will call .focus() on the newly instantiated dropdown
    // - dropdown is opened again (which the user does not want!)
    //
    // As a workaround, we shift the focus to an invisible span placed directly before the dropdown,
    // so that "tab" will refocus the dropdown if required.
    const focusTrap = document.createElement('span')
    focusTrap.tabIndex = -1
    element.parentElement.insertBefore(focusTrap, element)
    tomSelect.on('dropdown_close', () => {
      focusTrap.focus()
    })
  }

  // Clean up when the element gets removed from the DOM.
  return () => {
    tomSelect.destroy()
  }
})
